import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Web design is all about making efficient use of the available space. You don't want to clutter the screen
with too much content. This is the reason for common patterns like slide-in submenus, expansion panels,
popups or dialogs.`}</p>
    <p>{`Often, these elements are implemented as custom widgets with an insufficient hidden state. They use CSS code
like `}<InlineCode mdxType="InlineCode">{`opacity: 0`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`position: absolute; left: -10000px`}</InlineCode>{` to
hide the content only visually. But users can still access the content with the keyboard or assistive technologies.
What can we do about it?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGjDZJXjCX/xAAcEAADAAEFAAAAAAAAAAAAAAABAgMABBESExT/2gAIAQEAAQUCsUkkXFMMBl5p5tIB2ty3/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABoQAAIDAQEAAAAAAAAAAAAAAAABEjFhEBH/2gAIAQEABj8Cv1tmlEo7yz//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhoTFh/9oACAEBAAE/Ibj4QmlksdLktV5lKFgJ+4zhOXIced//2gAMAwEAAgADAAAAECTP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/ELiv/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQIBAT8Qg//EAB0QAQACAgIDAAAAAAAAAAAAAAERIQBRMdFBYZH/2gAIAQEAAT8QQhqDaNrqsMOyKkprcl/cRhPrs40AAFAUd4INCKHhx+Ux5Of/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sloth hanging on tree branch.",
          "title": "Sloth hanging on tree branch.",
          "src": "/static/30a9c9e15e8bc429a847e59efa54c12e/e5166/pexels-roxanne-minnish-sloth.jpg",
          "srcSet": ["/static/30a9c9e15e8bc429a847e59efa54c12e/f93b5/pexels-roxanne-minnish-sloth.jpg 300w", "/static/30a9c9e15e8bc429a847e59efa54c12e/b4294/pexels-roxanne-minnish-sloth.jpg 600w", "/static/30a9c9e15e8bc429a847e59efa54c12e/e5166/pexels-roxanne-minnish-sloth.jpg 1200w", "/static/30a9c9e15e8bc429a847e59efa54c12e/b17f8/pexels-roxanne-minnish-sloth.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Roxanne Minnish / pexels.com`}</em></p>
    <p>{`You can use the HTML attribute `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` to prevent any interaction with hidden content. A kind of
digital sloth, so to say. Let's take a closer look at the attribute that is now supported by all major browsers.`}</p>
    <h2>{`How the `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` attribute works`}</h2>
    <p>{`The inert property is a global HTML attribute that tells the browser to prevent any user interaction with a specific
section of content. The `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/#the-inert-attribute"
      }}>{`HTML specification`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<BoldText mdxType="BoldText">{`inert`}</BoldText>{` attribute is a boolean attribute that indicates, by its presence, that the element and
all its flat tree descendants `}{`[...]`}{` are to be made inert by the user agent.`}</p>
    </blockquote>
    <p>{`This means that all user input events for the element and its descendants are ignored, including focus events and events
from assistive technologies.`}</p>
    <p>{`For example: Let's say you place a registration form off-screen and let it slide into view
when the user presses a certain button. Adding `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` to the `}<InlineCode mdxType="InlineCode">{`<`}{`form`}{`>`}</InlineCode>{`
element ensures that a keyboard user cannot accidentally interact with the form elements while they're off-screen. The
content will also be hidden from screen reader users.`}</p>
    <p>{`The native `}<InlineCode mdxType="InlineCode">{`<`}{`dialog`}{`>`}</InlineCode>{` element also makes use of this. When you open a modal dialog
with `}<InlineCode mdxType="InlineCode">{`.showModal()`}</InlineCode>{`, the browser will automatically render all other content inert.
Find out more in my blog post `}<a parentName="p" {...{
        "href": "/native-dialog-element.en/"
      }}>{`“Why you should use the Native Dialog Element”`}</a>{`.`}</p>
    <h2>{`Use Case: Animated Navigation Menu`}</h2>
    <p>{`I've created a `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/fancy-css-playground/#/inert"
      }}>{`demo`}</a>{` for an animated navigation menu that
uses the `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` attribute for its hidden submenus. It works well for keyboard and screen reader users:`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/inert" title="Use Case for inert attribute" loading="lazy"></iframe>
    <p>{`The navigation menu is structured as an unordered list inside a nav element. The HTML code for a menu option and its
hidden submenu looks as follows:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<button type="button" aria-expanded="false" aria-controls="sub_menu_research">
    Research
</button>
<div id="sub_menu_research" class="subMenu" inert>
    <ul>
        <li><a href="/black-holes">Black Holes</a></li>
        <li><a href="/climate-change">Climate Change</a></li>
        <li><a href="/modern-way-of-work">Modern Way of Work</a></li>
    </ul>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In my CSS code, I reference the inert attribute to also visually hide the submenu:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.subMenu {
    overflow: hidden;
}

.subMenu[inert] > ul {
    transform: translateY(-100%);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`When the menu option is activated, the `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` attribute is removed and the submenu slides into view:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.subMenu:not([inert]) > ul {
    transform: translateY(0);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The creation of the slide animation is pretty easy and straightforward using the `}<InlineCode mdxType="InlineCode">{`transition`}</InlineCode>{`
CSS property:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.subMenu > ul {
    transition: transform 0.4s ease-in-out;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can view the `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/fancy-css-playground/tree/main/src/pages/InertAttribute"
      }}>{`complete source code here`}</a>{`.`}</p>
    <h2>{`Wouldn't `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{` do the job?`}</h2>
    <p>{`Some of you might be thinking: `}<ItalicText mdxType="ItalicText">{`“Why should I use the inert attribute? I can simply hide stuff with
display: none, duh!”`}</ItalicText>{` You're right. When you apply the CSS property `}<InlineCode mdxType="InlineCode">{`display: none`}</InlineCode>{`
to an element, it won't be rendered by the browser and is hidden from assistive technologies. The downside: You
won't be able to animate the element any more.`}</p>
    <p>{`Using the `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` attribute gives you more flexibility. You can let your content fade in and out,
slide into view or create any sophisticated animation you can think of. Also, you'll want to use the attribute for content
that is visible but should not be interacted with. For example, the partially visible previous and next slide in
a carousel widget.`}</p>
    <h2>{`Browser Support`}</h2>
    <p>{`Firefox finally added support for `}<InlineCode mdxType="InlineCode">{`inert`}</InlineCode>{` in version 112. This means that
`}<a parentName="p" {...{
        "href": "https://caniuse.com/mdn-api_htmlelement_inert"
      }}>{`all major browsers`}</a>{` support the attribute now.`}</p>
    <p>{`Yay! Let's go and build cool stuff with it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      